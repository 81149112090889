import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  UNDO_COMMAND
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType } from '@lexical/utils';

import { CustomParagraphNode } from '../CustomParagraphNode';

import { ArrowUp, ArrowDown, ArrowCounterclockwise, XLg } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const updateFocus = (paragraphNode) => {
    editor.update(() => {
      const currentFocus = paragraphNode.getFocus();

      if (currentFocus !== 'true') {
        const root = $getRoot();
        const children = root.getChildren();

        children.forEach((node) => {
          if (node instanceof CustomParagraphNode) {
            node.setFocus('false');
          }
        });

        paragraphNode.setFocus('true');
      }
    });
  };

  const handleNavigateUp = (event) => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);

        if (paragraphNode) {
          const previousNode = paragraphNode.getPreviousSibling();
          const domNode = editor.getElementByKey(previousNode?.getKey());
          
          if (previousNode instanceof CustomParagraphNode) {
            event.preventDefault();

            previousNode?.selectEnd();
            updateFocus(previousNode);

            domNode?.scrollIntoView({ behavior: 'smooth', block: 'start' });

						return true;
          }
        }
      }

			return false;
    });
  };

		const handleNavigateDown = (event) => {
			editor.update(() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					const anchorNode = selection.anchor.getNode();
					const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);
	
					if (paragraphNode) {
						const nextNode = paragraphNode.getNextSibling();
            const domNode = editor.getElementByKey(nextNode?.getKey());

						if (nextNode instanceof CustomParagraphNode) {
              event.preventDefault();

              nextNode?.selectStart();
              updateFocus(nextNode);

              domNode?.scrollIntoView({ behavior: 'smooth', block: 'start' });

							return true;
						}
					}
				}

				return false;
			});
		};

  const handleDelete = () => {
    editor.update(() => {
      const selection = $getSelection();
  
      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);
  
        if (paragraphNode) {
          const nextNode = paragraphNode.getNextSibling();
          const previousNode = paragraphNode.getPreviousSibling();
      
          paragraphNode.remove();
  
          if (nextNode instanceof CustomParagraphNode) {
            nextNode?.selectStart();
            updateFocus(nextNode);
          } else if (previousNode instanceof CustomParagraphNode) {
            previousNode?.selectEnd();
            updateFocus(previousNode);
          }

          return true;
        }
      }

      return false;
    })
  };

  const handleUndo = () => {
    editor.update(() => {
      editor.dispatchCommand(UNDO_COMMAND, undefined);
    
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);

        if (paragraphNode) {
          const nextNode = paragraphNode.getNextSibling();
          const previousNode = paragraphNode.getPreviousSibling();

          if (nextNode instanceof CustomParagraphNode) {
            const domNode = editor.getElementByKey(nextNode?.getKey());
            domNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else if (previousNode instanceof CustomParagraphNode) {
            const domNode = editor.getElementByKey(previousNode?.getKey());
            domNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }
    })
  };

  return (
    <div className="editor-toolbar">
      <Button onClick={handleNavigateUp}>
        <ArrowUp
          width={18}
          height={18}
        />
      </Button>
      <Button onClick={handleNavigateDown}>
        <ArrowDown
          width={18}
          height={18}
        />
      </Button>
      <Button onClick={handleDelete}>
        <XLg
          width={18}
          height={18}
        />
      </Button>
      <Button onClick={handleUndo}>
        <ArrowCounterclockwise
          width={18}
          height={18}
        />
      </Button>
    </div>
  );
};

export default ToolbarPlugin;