import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    debug:true,
    lng:"ru",
    resources: {
        ru: {
            translation: {
            login: "Войти",
            emailRequired: "Введите Email",
            password: "Пароль",
            passwordRequired: "Введите пароль",
            registration: "Регистрация",
            yourProjects: "Ваши проекты",
            addFiles: "Добавить файлы",
            navigation: "Навигация",
            accountSettings: "Настройки аккаунта",
            exit: "Выйти",
            language: "Язык",
            newProject: "Новый проект",
            scenario: "Сценарий",
            generalPlan: "Общий план",
            secondPlan: "Второй план",
            thirdPlan: "Третий план",
            chooseFile: "Выберите файл",
            audio: "Выберите в качестве основной аудиодорожки",
            createProject: "Создать проект",
            oneOreMore: "Выберите одно или несколько видео",
            needToChoose: "Требуется выбрать видео",
            sure: "Вы уверены?",
            reallySure: "Вы действительно хотите удалить проект?",
            yes: "Да",
            no: "Нет",
            preview: "Тизер",
            cut: "Обрезка начала/конца записи",
            speedUpSilence: "Ускорить тишину",
            speedUpVideo: "Ускорить видео",
            verticalVideo: "Вертикальное видео",
            planTimer: "Таймер переключения планов",
            processing: "Обработка",
            timeLeft: "Оставшееся время",
            cansel: "Отменить",
            error: "Ошибка",
            downloadMainVideo: "Скачать основное видео",
            downloadPreview: "Скачать тизер",
            queue: "в очереди",
            beginRender: "Начать обработку",
            createPreview: "Создать тизер",
            delete: "Удалить",
            accountSettings: "Настройки аккаунта",
            firstName: "Имя",
            firstNameRequired: "Требуется ввести Имя",
            secondName: "Фамилия",
            secondNameRequired: "Требуется ввести Фамилию",
            phone: "Номер телефона",
            phoneRequired: "Введите номер телефона",
            phoneFormat: "Неверный формат номера телефона",
            save: "Сохранить",
            changePassword: "Сменить пароль",
            oldPass: "Старый пароль",
            oldPassRequired: "Требуется ввести старый пароль",
            newPass: "Новый пароль",
            newPassRequired: "Требуется ввести новый пароль",
            minLength: "Минимальная длина пароля 10 символов",
            minPassLength: "Минимальная длина пароля is 1 символ",
            passChanged: "Пароль изменён",
            wrongFormat: "Неверный формат телефонного номера",
            wrongEmailFormat: "Неверный формат Email",
            saveSuccess: "Сохранено",
            success: "Успешно",
            back: "Назад",
            instruction: "Инструкция",
            start: "Начало работы",
            prepareFormat: "Выберите формат видео, который хотите смонтировать с помощью приложения VideoNeuron.",
            threeCameras: "Три камеры",
            threeCamerasDecs: "Видео с двумя спикерами, снятое на три камеры, где 1 видео обязательно общий план, второе и третье крупный план. Не забудьте поставить галочку, под видео, с которого хотите, чтобы был взят звук в итоговый ролик.",
            twoCameras: "Две камеры",
            twoCamerasDesc: "Видео с одним спикером, снятым на две камеры. VideoNeuron определит в какую камеру смотрит спикер, главное, чтобы обе камеры захватывали его лицо. Не забудьте поставить галочку, под видео, с которого хотите, чтобы был взят звук в итоговый ролик.",
            oneCamera: "Одна камера",
            oneCameraDesc: "Видео с одним спикером, снятое на одну камеру. VideoNeuron определит моменты пауз и ускорит их. Если Вы снимались с прозрачной доской, он, также, ускорит сцены, где вы стираете с доски или пишите на ней.",
            changeSettings: "Меняйте настройки",
            changeSettingsDesc: " Немаловажным остается, что Вы можете корректировать настройки после первой обработки видео, таким образом, редактировать уже отснятый материал. Повторные обработки занимают гораздо меньше времени.",
            shareCarousel: "Делитесь результатом",
            shareCarouselDesc: "После того, как Ваше видео будет обработано, вы можете скачать его или поделиться через удобную для Вас соц. Сеть.",
            chooseVideofile: "Выберите видеофайл",
            chooseAudioAndCreate: "Выберите аудиодорожку и создайте проект",
            scenarioNextStep: "Следующий шаг: Выберите сценарий",
            nextStep: "Следующий шаг:",
            subtitles: "Монтаж по субтитрам",
            subtitlesRender: "Обработать по субтитрам",
            xml: "Скачать xml файл",
            passwordChange: "Восстановление пароля",
            sendCode: "Отправить письмо",
            original: "Оригинал",
            downloading: "Загрузка...",
            onlyVideo: "Разрешены только видеофайлы",
            dragAndDrop: "Перетащите файлы или нажмите здесь",
            uploadError: "Произошла ошибка во время создания проекта",
            uploadChunk: "Загрузка видеофайлов проекта",
            uploadProxy: "Создание копий видеофайлов для обработки проекта",
            uploadML: "Обработка файлов проекта",
            duplicateFile: "Файл с таким именем уже загружен",
            addPlan: "Добавить план",
            },
        },
        en: {
            translation: {
            login: "Login",
            emailRequired: "Enter your Email",
            password: "Password",
            passwordRequired: "Enter your password",
            passwordChange: "Password recovery",
            registration: "Registration",
            yourProjects: "Your projects",
            addFiles: "Add files",
            navigation: "Navigation",
            accountSettings: "Account settings",
            exit: "Exit",
            sendCode: "Send mail",
            language: "Language",
            newProject: "New project",
            scenario: "Scenario",
            generalPlan: "Overall plan",
            secondPlan: "Second plan",
            thirdPlan: "Third plan",
            chooseFile: "Choose files",
            audio: "Select as main audio source",
            createProject: "Create project",
            oneOreMore: "Choose one ore more files",
            needToChoose: "Need to choose video",
            sure: "Are you sure?",
            reallySure: "Are you sure you want to delete the project?",
            yes: "Yes",
            no: "No",
            preview: "Teaser",
            cut: "Cut the beginnig/ending of video",
            speedUpSilence: "Speed up silence",
            speedUpVideo: "Speed up video",
            verticalVideo: "Vertical video",
            planTimer: "Plan change timer",
            processing: "Rendering",
            timeLeft: "Time left",
            cansel: "Cansel",
            error: "Error",
            downloadMainVideo: "Download main video",
            downloadPreview: "Download teaser",
            queue: "In queue",
            beginRender: "Start render",
            createPreview: "Create teaser",
            delete: "Delete",
            accountSettings: "Account Settings",
            firstName: "First Name",
            firstNameRequired: "First name required",
            secondNameRequired: "Second name required",
            secondName: "Second Name",
            phone: "Phone",
            phoneRequired: "Phone number required",
            phoneFormat: "Wrong phone number format",
            save: "Save",
            changePassword: "Change Password",
            oldPass: "Old password",
            oldPassRequired: "Old password is required",
            newPass: "New password",
            newPassRequired: "New password is required",
            minLength: "Password minimum length is 10 symbols",
            minPassLength: "Password minimum length is 1 symbol",
            passChanged: "Password changed",
            wrongFormat: "Entered value does not match phone format",
            wrongEmailFormat: "Entered value does not match Email format",
            saveSuccess: "Saved",
            success: "Success",
            back: "Go back",
            instruction: "Instruction",
            start: "Lets start",
            prepareFormat: "Choose video format, that you want to render with VideoNeuron app.",
            threeCameras: "Three cameras",
            threeCamerasDecs: "Two speakers video, made with three cameras, with first video is overall plan and second/third videos are close-ups. Don't forget to choose audio source for your result video.",
            twoCameras: "Two cameras",
            twoCamerasDesc: "Video with one speaker, made with two cameras. VideoNeuron will detect, in which camera speaker is looking at, just make sure, that all the cameras detecting speakers face. Don't forget to choose audio source for your result video.",
            oneCamera: "One camera",
            oneCameraDesc: "Video with one speaker, made with one camera. VideoNeuron will speed-up parts with silence and pauses. If you have used an invisible board, Videoneuron will speed-up washing process.",
            changeSettings: "Change settings",
            changeSettingsDesc: "After first render, you can change video settings. Further renders will take less time.",
            shareCarousel: "Share your videos",
            shareCarouselDesc: "After render, you can download result video or share it with social media.",
            chooseVideofile: "Choose video file",
            chooseAudioAndCreate: "Choose audio source and create project",
            scenarioNextStep: "Next step: Choose scenario",
            nextStep: "Next step:",
            subtitles: "Mounting by subtitles",
            subtitlesRender: "Rendering by subtitles",
            xml: "Download xml file",
            original: "Original",
            downloading: "Downloading...",
            onlyVideo: "Only video files are allowed",
            dragAndDrop: "Drag and drop files or click here",
            uploadError: "An error occurred during project creation",
            uploadChunk: "Uploading project video files",
            uploadProxy: "Creating copies for processing project video files",
            uploadML: "Project file processing",
            duplicateFile: "A file with this name has already been downloaded",
            addPlan: "Add plan",
        }
    }
    }
})