import { useEffect, useCallback } from 'react';

import {
  $getRoot,
	$getSelection,
	$isRangeSelection,
	KEY_DELETE_COMMAND,
	KEY_BACKSPACE_COMMAND,
	COMMAND_PRIORITY_LOW
} from "lexical";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType } from '@lexical/utils';

import { CustomParagraphNode } from '../CustomParagraphNode';

const DeleteParagraphPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const updateFocus = useCallback((paragraphNode) => {
    editor.update(() => {
      const currentFocus = paragraphNode.getFocus();
      if (currentFocus !== 'true') {
        const root = $getRoot();
        const children = root.getChildren();

        children.forEach((node) => {
          if (node instanceof CustomParagraphNode) {
            node.setFocus('false');
          }
        });

        paragraphNode.setFocus('true');
      }
    });
  }, [editor]);

  const onDelete = useCallback((event) => {
    editor.update(() => {
      const selection = $getSelection();
  
      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);
  
        if (paragraphNode) {
          event.preventDefault();
          
          const nextNode = paragraphNode.getNextSibling();
          const previousNode = paragraphNode.getPreviousSibling();
          
          paragraphNode.remove();
  
          if (nextNode instanceof CustomParagraphNode) {
            nextNode?.selectStart();
            updateFocus(nextNode);
          } else if (previousNode instanceof CustomParagraphNode) {
            previousNode?.selectEnd();
            updateFocus(previousNode);
          }
  
          return true;
        }
      }
  
      return false;
    })
  }, [editor, updateFocus]);

  useEffect(() => {
    const unregister = editor.registerCommand(
      KEY_DELETE_COMMAND,
      onDelete,
      COMMAND_PRIORITY_LOW
    );

    const unregisterBackspace = editor.registerCommand(
      KEY_BACKSPACE_COMMAND,
      onDelete,
      COMMAND_PRIORITY_LOW
    );

    return () => {
      unregister();
      unregisterBackspace();
    };
  }, [editor, onDelete]);

  return null;
};

export default DeleteParagraphPlugin;