import './index.scss';

// import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import { ParagraphNode } from "lexical";

import AddTextPlugin from './plugins/AddTextPlugin';
import ParagraphFocusPlugin from './plugins/ParagraphFocusPlugin';
import UpdateParagraphsStatePlugin from './plugins/UpdateParagraphsStatePlugin';
import DeleteParagraphPlugin from './plugins/DeleteParagraphPlugin';
import ArrowNavigationPlugin from './plugins/ArrowNavigationPlugin';
import PreventNewParagraphPlugin from './plugins/PreventNewParagraphPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';

import { CustomParagraphNode } from './CustomParagraphNode';

import editorTheme from './editorTheme';

function onError(error) {
  console.error(error);
}

function TextEditor({ segments, setModifiedSegments, loader }) {
  const initialConfig = {
    namespace: 'textEditor',
    theme: editorTheme,
    onError,
    nodes: [
      CustomParagraphNode,
      {
        replace: ParagraphNode,
        with: (_node) => {
          return new CustomParagraphNode();
        }
      }
    ]
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <AddTextPlugin segments={segments} />
      <div className="editor-container">
        <ToolbarPlugin />
        <RichTextPlugin
          contentEditable={<ContentEditable className="content-editable"/>}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </div>
      <HistoryPlugin />
      {/* <AutoFocusPlugin /> */}
      <ParagraphFocusPlugin />
      <UpdateParagraphsStatePlugin
        setState={setModifiedSegments}
        loader={loader}
      />
      <DeleteParagraphPlugin />
      <ArrowNavigationPlugin />
      <PreventNewParagraphPlugin />
    </LexicalComposer>
  );
}

export default TextEditor;