import { useEffect, useCallback } from 'react';

import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  KEY_ARROW_UP_COMMAND,
  KEY_ARROW_DOWN_COMMAND,
  COMMAND_PRIORITY_NORMAL,
} from "lexical";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType } from '@lexical/utils';

import { CustomParagraphNode } from '../CustomParagraphNode';

const ArrowNavigationPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const updateFocus = useCallback((paragraphNode) => {
    editor.update(() => {
      const currentFocus = paragraphNode.getFocus();
      if (currentFocus !== 'true') {
        const root = $getRoot();
        const children = root.getChildren();

        children.forEach((node) => {
          if (node instanceof CustomParagraphNode) {
            node.setFocus('false');
          }
        });

        paragraphNode.setFocus('true');
      }
    });
  }, [editor]);

  const onArrowUpPress = useCallback((event) => {
    editor.update(() => {
      const selection = $getSelection();
      
      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);
        
        if (paragraphNode) {
          const previousNode = paragraphNode.getPreviousSibling();
          
          if (previousNode instanceof CustomParagraphNode) {
            event.preventDefault();
  
            previousNode?.selectEnd();
            updateFocus(previousNode);
  
            return true;
          }
        }
      }
      
      return false;
    })
  }, [editor, updateFocus]);

  const onArrowDownPress = useCallback((event) => {
    editor.update(() => {
      const selection = $getSelection();
      
      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);
        
        if (paragraphNode) {
          const nextNode = paragraphNode.getNextSibling();
  
          if (nextNode instanceof CustomParagraphNode) {
            event.preventDefault();
  
            nextNode?.selectStart();
            updateFocus(nextNode);
  
            return true;
          }
        }
      }
    
      return false;
    })
  }, [editor, updateFocus]);

  useEffect(() => {
    const unregisterArrowUp = editor.registerCommand(
      KEY_ARROW_UP_COMMAND,
      onArrowUpPress,
      COMMAND_PRIORITY_NORMAL,
    );

    const unregisterArrowDown = editor.registerCommand(
      KEY_ARROW_DOWN_COMMAND,
      onArrowDownPress,
      COMMAND_PRIORITY_NORMAL,
    );

    return () => {
      unregisterArrowUp();
      unregisterArrowDown();
    };
  }, [editor, onArrowDownPress, onArrowUpPress]);

  return null;
};

export default ArrowNavigationPlugin;