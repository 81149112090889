import Card from "../../components/card/Card";
import Slider from "../../components/input/Slider";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Alert } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { ButtonSubmit } from "../../components/button/ButtonSubmit";
import { Checkbox } from "../../components/checkbox/Checkbox";
import projectClient from "../../helpers/projectClient";
import renderClient from "../../helpers/renderClient";
import { Settings } from "../../contract/project";
import { Config } from "../..//config";
import { confirmAlert } from "react-confirm-alert";
import authHelper from "../../helpers/authHelper";
import ShareVideo from "./components/ShareVideo";
import { Download } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import { getPeriodText } from "../../helpers/timeHelper";
import { useTranslation } from "react-i18next";
import subtitlesClient from "../../helpers/subtitlesClient";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import "./project.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import TextEditor from '../../components/textEditor';
import VideoPlayerNew2 from '../../components/videoPlayer/VideoPlayerNew2';

export default function Project() {
    const { id } = useParams("id");
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [qpos, setQPos] = useState(-1);
    const [speedUpSilenceCheck, setSpeedUpSilenceCheck] = useState(false);
    const [speedUpAllCheck, setSpeedUpAllCheck] = useState(false);
    const [isVerticalCheck, setIsVerticalCheck] = useState(false);
    const [switchPlanTimeCheck, setCheck3] = useState(false);
    const { t } = useTranslation();

    const [project, setProject] = useState();
    const [projectName, setProjectName] = useState("");

    const minSwitchTime = 3; // ToDo - значение для теста
    const maxSwitchTime = 30;
    const maxSpeedUpSilence = 20;

    const [speedUpSilence, setSpeedUpSilence] = useState(2);
    const [speedUpAll, setSpeedUpAll] = useState(1);
    const [switchTime, setSwitchTime] = useState(minSwitchTime);
    const [videoSource, setVideoSource] = useState("");
    const [videoPreviewSource, setVideoPreviewSource] = useState("");
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const [overlayContent, setOverlayContent] = useState("");
    const [options, $options] = useState();
    const [audioSourceIndex, $audioSourceIndex] = useState(0);
    const [duration, $duration] = useState(0);
    const [offset, setOffset] = useState([0, 0]);

    const [carouselIndex, setCarouselIndex] = useState(0);

    const [showError, setShowError] = useState(true);
    const [error, setError] = useState(false);

    const [currentVideoType, setCurrentVideoType] = useState('original');

    const videoRef = useRef(null);
    const videoRefPreview = useRef(null);
    const carouselRef = useRef(null);

    const showOverlay = searchParams.has("overlay");

    let storyboard;
    let updateInterval;
    let storyboardLast;

    const updateOverlay = (v) => {
        if (!v || !v.current) return;

        const ctime = v.current.currentTime;
        const vtime = new Date(ctime * 1000).toISOString().substring(11, 19);

        setOverlayContent(`${vtime} - ${storyboardLast}`);

        let story;
        for (const t in storyboard) {
            if (t > ctime) {
                story = storyboard[t];
                break;
            }
        }
        if (!story) return;
        storyboardLast = `${story.rule} [${story.interval.actualStart} - ${story.interval.actualEnd}]`;

        console.log(story.interval);
    };

    const getVideoSource = useCallback(async (id, proj) => {
        const token = authHelper.getToken();
        let result = '';
        let videoUrl = `${Config.host}/storage/${id}/stream/`;

        if (token != null) {
            const url = new URL(videoUrl);
            url.searchParams.set("token", token);
            url.searchParams.set("key", proj.renderKey);
            videoUrl = url.toString();
        }

        let res = await projectClient.get(id);

        if (res.status === 4) {
            await renderClient.isFileExists(videoUrl)
            .then(rc => {
                setVideoSource(rc.url);
                result = rc.url;   
            })
            .catch(er => {
                setVideoSource(null);
                result = null;
            });
        }
        
        return result;
    }, []);

    const getPreviewVideoSource = useCallback(async (id, proj) => {
        const token = authHelper.getToken();
        let result = '';
        let videoPreviewUrl = `${Config.host}/storage/${id}/previewstream/`;

        if (token != null) {
            const previewUrl = new URL(videoPreviewUrl);
            previewUrl.searchParams.set("token", token);
            previewUrl.searchParams.set("key", proj.renderKey);
            videoPreviewUrl = previewUrl.toString();
        }
        
        let res = await projectClient.get(id);
        
        if (res.status === 4) {
            await renderClient.isFileExists(videoPreviewUrl)
            .then(rc => {
                setVideoPreviewSource(rc.url);
                result = rc.url;
            }).catch(er => {
                setVideoPreviewSource(null);
                result = null;
            });
        }
        
        return result;
    }, []);

    const updateVideoSource = useCallback(async (id, proj) => {
        await getVideoSource(id, proj);
        await getPreviewVideoSource(id, proj);

        if (videoRef.current && videoRef.current.getInternalPlayer()) {
            videoRef.current.getInternalPlayer().load();
        }
        if (videoRefPreview.current && videoRefPreview.current.getInternalPlayer()) {
            videoRefPreview.current.getInternalPlayer().load();
        }
    }, [getPreviewVideoSource, getVideoSource]);

    const updateProject = useCallback(async (project) => {
        if (project == null) {
            setError(true);
            return;
        }

        const getFileName = () => {
            const d = new Date();
            const dFormat = `${d.getHours()}-${d.getMinutes()}`;
            return dFormat;
        }

        setProject(project);
        setProjectName(getFileName);
        const opts = Object.keys(project.fileNames).map((x, i) => ({
            value: i,
            label: x,
        }));
        $options(opts);
        setQPos(project.positionInQueue);
        setCheck3(true);
        const stg = project.settings;
        let duration = 0;
        if (project?.referenceVideo?.size?.duration) {
            duration = timeSpanToSec(project.referenceVideo.size.duration);
            $duration(duration);
            setOffset([0, duration]);
        }

        if (stg !== null && stg !== undefined) {
            const truncate = stg.truncateSec || duration;
            setOffset([stg.offsetSec, truncate]);
            setSpeedUpSilenceCheck(stg.needSpeedUpSilence);
            setSpeedUpAllCheck(stg.needSpeedUpAll);
            setIsVerticalCheck(stg.isVertical);
            setSpeedUpSilence(stg.speedUpSilence);
            setSpeedUpAll(stg.speedUpAll);
            setSwitchTime(
                stg.switchTime < minSwitchTime ? minSwitchTime : stg.switchTime
            );
            $audioSourceIndex(stg.audioSourceIndex);
        }

        await updateVideoSource(id, project);
    }, [id, updateVideoSource]);

    useEffect(() => {
        (async () => {
            let res = await projectClient.get(id);
            await updateProject(res);
        })();

        if (showOverlay && !storyboard) {
            storyboard = "loading...";
            (async () => {
                const sb = await renderClient.getStoryboard(id);
                if (!sb) return;
                storyboard = {};
                for (const i of sb.intervals) {
                    const rule = i.clips[0].reason.ruleId;
                    const ts = i.actualEnd;
                    const asDate = new Date("01.01.1970 " + ts + " GMT");
                    const time = asDate.getTime() / 1000;
                    storyboard[time] = { rule: rule, interval: i };
                }

                if (videoRef.current) {
                    videoRef.current.ontimeupdate = () => {
                        updateOverlay(videoRef);
                    };
                }
                if (videoRefPreview.current) {
                    videoRefPreview.current.ontimeupdate = () => {
                        updateOverlay(videoRefPreview);
                    };
                }
            })();
        }

        return () => { };
    }, []);

    const render = async () => {
        if (videoRef.current) {
            videoRef.current.getInternalPlayer().pause();
        }
        if (videoRefPreview.current) {
            videoRefPreview.current.getInternalPlayer().pause();
        }

        var settings = new Settings();
        settings.needSpeedUpSilence = speedUpSilenceCheck;
        settings.needSpeedUpAll = speedUpAllCheck;
        settings.isVertical = isVerticalCheck;
        settings.speedUpSilence = speedUpSilence ? speedUpSilence : settings.speedUpSilence;
        settings.speedUpAll = speedUpAll ? speedUpAll : settings.speedUpAll;
        settings.switchTime = switchTime;
        settings.audioSourceIndex = audioSourceIndex;
        settings.offsetSec = offset[0];
        settings.truncateSec = offset[1];
        try {
            let res = await renderClient.render(id, settings);
            setQPos(res.positionInQueue);

            res = await projectClient.get(id);
            await (res);
        } catch (error) {
            console.log(error);
            clearInterval(updateInterval);
            return;
        }

        refresh();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setProject({ status: 3 });
        render();
    };

    const handlePreview = async(e) => {
        if (videoRef.current) {
            videoRef.current.getInternalPlayer().pause();
        }
        if (videoRefPreview.current) {
            videoRefPreview.current.getInternalPlayer().pause();
        }
        
        try {
            let res = await renderClient.renderPreview(id);
            setQPos(res.positionInQueue);

            res = await projectClient.get(id);
            await updateProject(res);
        } catch (error) {
            console.log(error);
            clearInterval(updateInterval);
            return;
        }

        refresh();
    };

    const removeProject = async () => {
        confirmAlert({
            title: t("sure"),
            message: t("reallySure"),
            buttons: [
                {
                    label: t("yes"),
                    onClick: async () => {
                        await projectClient.remove(id);
                        navigate(`/`);
                    },
                },
                {
                    label: t("no"),
                    onClick: () => { },
                },
            ],
        });
    };

    // Субтитры
    const [subtitleRenderId, setSubtitleRenderId] = useState(null);
    const [subtitleLoader, setSubtitleLoader] = useState(false);
    const [subtitleCutLoader, setSubtitleCutLoader] = useState(false);
    const [subtitleSegments, setSubtitleSegments] = useState([]);
    const [subtitleCheck, setSubtitleCheck] = useState(true);
    const [subtitleRender, setSubtitleRender] = useState(false);
    const [modifiedSegments, setModifiedSegments] = useState([]);
	const [subtitlesUrl, setSubtitlesUrl] = useState(null);

    const addSubtitles = useCallback(async () => {
        const res = await subtitlesClient.addSubtitles(id);
        
        if (res.message?.renderId) setSubtitleLoader(true);
        else return;

        setSubtitleRenderId(res.message?.renderId);
    }, [id]);

    const updateSubtitles = useCallback(async () => {
		try {
			const webVttText = await subtitlesClient.getSubtitlesWebVtt(id);

			if (webVttText) {
				const blob = new Blob([webVttText], { type: 'text/vtt' });
				const url = URL.createObjectURL(blob);
                
				setSubtitlesUrl(url);
			} else {
				console.log("Субтитры не найдены");
			}
		} catch (error) {
			console.log("Ошибка при получении субтитров:", error);
		}
	}, [id]);

    const getSubtitles = useCallback(async () => {
        let res = await subtitlesClient.getSubtitles(subtitleRenderId);

        if (res?.currentStatus !== 4) {
            setTimeout(getSubtitles, 10000);
        } else {
            updateSubtitles();

            setSubtitleLoader(false);
            setSubtitleSegments(res?.segments);
            setSubtitleRender(true);
        }
    }, [subtitleRenderId, updateSubtitles]);

    const getCutSegmentsVideo = useCallback(async () => {
        let res = await subtitlesClient.getSubtitles(subtitleRenderId);

        if (res?.currentStatus !== 5) {
            setTimeout(getCutSegmentsVideo, 10000);
        } else {
            let res = await projectClient.get(id);
            await updateProject(res);

            updateSubtitles();
            setSubtitleCutLoader(false);
            setSubtitleRender(false);
        }
    }, [id, subtitleRenderId, updateProject, updateSubtitles]);

    const postSubtitles = useCallback(async () => {
        if (!subtitleRenderId && modifiedSegments.length === 0) return;
        setSubtitleCutLoader(true);

        const res = await subtitlesClient.cutSegments({renderId: subtitleRenderId, segments: modifiedSegments });

        if (res?.message.isSuccess) getCutSegmentsVideo();
        else {
            setSubtitleCutLoader(false);
            return;
        }
    }, [getCutSegmentsVideo, modifiedSegments, subtitleRenderId]);

    useEffect(() => {
        if (modifiedSegments.length > 0) {
            postSubtitles();
        }
    }, [modifiedSegments, postSubtitles]);

    useEffect(() => {
        if (subtitleLoader) {
            getSubtitles();
        }
    }, [getSubtitles, subtitleLoader]);

    const createXmlAndDownload = async () => {
        const xml = await subtitlesClient.addXml(id);

        if (xml) {
            setIsLoadingFiles(true);
            const projectFiles = await subtitlesClient.downloadFiles(id);

            const url = window.URL.createObjectURL(projectFiles);
            const downloadLink = document.createElement('a');

            downloadLink.href = url;
            downloadLink.setAttribute('download', `project_files_${id}.zip`);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setIsLoadingFiles(false);
        }
    };

    const cancelProject = async () => {
        await renderClient.cancel(id);
        navigate(`/`);
    };

    useEffect(() => {
        updateInterval = setInterval(async () => {
            let res = await projectClient.get(id);

                await updateProject(res);
            

            if (res == null || res.status !== 3) {
                
                // setTimeout(async () => {
                    try {
                        await getVideoSource(id, res).then(prv => {
                            if (prv?.length > 0) {
                                document.getElementById("generalVideoID").src = prv;
                                //document.getElementById("generalVideoID").load();
                            }
                        });
                        await getPreviewVideoSource(id, res).then(prv => {
                            if (prv?.length > 0) {
                                document.getElementById("previewVideoID").src = prv;
                                //document.getElementById("previewVideoID").load();
                            }
                        });
                        clearInterval(updateInterval);
                    } catch(errr) {
                        console.log(errr);
                    }
                // }, 1000);
            }
        }, 3000);

        return () => {
            clearInterval(updateInterval);
        };
    }, []);

    const refresh = () => {
        window.location.reload();
    };

    const timeSpanToTime = (timespan) => {
        const dt = new Date("1970-01-01T" + timespan);
        let time = "";
        const hrs = dt.getHours();
        if (hrs) time += `${hrs} hours `;
        const mins = dt.getMinutes();
        if (mins) time += `${mins} min `;
        const secs = dt.getSeconds();
        if (secs) time += `${secs} sec`;

        return time || "0 sec";
    };

    const timeSpanToSec = (timespan) => {
        const dt = new Date("1970-01-01T" + timespan);
        const dt0 = new Date("1970-01-01T00:00:00");
        const msecs = dt.getTime() - dt0.getTime();
        return msecs / 1000;
    };

    const secToTimeSpan = (sec) => {
        if (isNaN(sec)) return null;
        const res = new Date(sec * 1000).toISOString().slice(11, 19);
        return res;
    };

    function handleChange(selectedIndex) {
        setCarouselIndex(selectedIndex);
        if (!selectedIndex && videoRefPreview.current) {
            videoRefPreview.current.getInternalPlayer().pause();
            setCurrentVideoType("original");
        } else if (videoRef.current) {
            videoRef.current.getInternalPlayer().pause();
            setCurrentVideoType("preview");
        }
    }

    const renderPlayer = () => {
        return (
            project?.status === 4 && (
                <>
                    {videoPreviewSource ? (
                        <div className="col-12">
                            <div className="d-flex gap-2 mt-3 mb-1">
                                <Button
                                    className={`${currentVideoType === "original" ? "active" : ""}`}
                                    style={{ width: '100px' }}
                                    variant='primary'
                                    size='sm'
                                    onClick={() => carouselRef.current.moveTo(0)}
                                >
                                    {t("original")}
                                </Button>
                                <Button
                                    className={currentVideoType === "preview" ? "active" : ""}
                                    style={{ width: '100px' }}
                                    variant='primary'
                                    size='sm'
                                    onClick={() => carouselRef.current.moveTo(1)}
                                >
                                    {t("preview")}
                                </Button>
                            </div>
                            <Carousel
                                ref={carouselRef}
                                onChange={handleChange}
                                showThumbs={false}
                                showStatus={false}
                                showArrows={false}
                                showIndicators={false}
                            >
                                <div>
                                    <VideoPlayerNew2
                                        videoId="generalVideoID"
                                        src={videoSource}
                                        videoRef={videoRef}
                                        subtitlesUrl={subtitlesUrl}
                                        updateSubtitles={updateSubtitles}
                                        isVertical={isVerticalCheck}
                                    />
                                </div>
                                <div>
                                    <VideoPlayerNew2
                                        videoId="previewVideoID"
                                        src={videoPreviewSource}
                                        videoRef={videoRefPreview}
                                    />
                                    <p className="legend" style={{bottom: "15%"}}>{t("preview")}</p>
                                </div>
                            </Carousel>


                        </div>
                    ) : (
                        <div className="col-12">
                            <VideoPlayerNew2
                                videoId="generalVideoID"
                                src={videoSource}
                                videoRef={videoRef}
                                subtitlesUrl={subtitlesUrl}
                                updateSubtitles={updateSubtitles}
                                isVertical={isVerticalCheck}
                            />
                        </div>
                    )}
                </>
            )
        );
    };

    const renderControls = () => {
        return (
            project?.status !== 3 && (
                <>
                    <div className="col-12 mt-5">
                        <label className="form-label text-secondary">
                            {t("cut")}
                        </label>
                        <Slider
                            max={duration}
                            min={0}
                            step={0.01}
                            value={offset}
                            onChange={setOffset}
                            format={secToTimeSpan}
                            isRange={true}
                        />
                    </div>

                    {(project?.preset === "interview" || project?.preset === "speech") && (
                        <div className="col-12">
                            <div className="form-label text-secondary">
                                {t("planTimer")}
                            </div>
                            <div className="mb-1">
                                {switchPlanTimeCheck && (
                                    <Slider
                                        min={minSwitchTime}
                                        max={maxSwitchTime}
                                        step={1}
                                        value={switchTime}
                                        onChange={setSwitchTime}
                                        format={(v) => `${v} sec`}
                                    />
                                )}
                            </div>
                        </div>
                    )}

                    {(project?.preset === "speech" ||
                        project?.preset === "single") && (
                            <div className="col-12">
                                <Checkbox
                                    text={t("speedUpSilence")}
                                    id="speed-up-silence"
                                    value={speedUpSilenceCheck}
                                    setValue={setSpeedUpSilenceCheck}
                                />

                                {speedUpSilenceCheck && (
                                    <Slider
                                        min={2}
                                        max={maxSpeedUpSilence}
                                        step={1}
                                        value={speedUpSilence}
                                        onChange={setSpeedUpSilence}
                                        format={(v) => `${v}x`}
                                    />
                                )}
                            </div>
                        )}

                    <div className="col-12">
                        <Checkbox
                            text={t("speedUpVideo")}
                            id="speed-up-all"
                            value={speedUpAllCheck}
                            setValue={setSpeedUpAllCheck}
                        />

                        {speedUpAllCheck && (
                            <Slider
                                min={1}
                                max={100}
                                step={1}
                                value={speedUpAll}
                                onChange={setSpeedUpAll}
                                format={(v) => `${v}%`}
                            />
                        )}
                    </div>

                    <div className="col-12">
                        <Checkbox
                            text={t("verticalVideo")}
                            id="vertical"
                            value={isVerticalCheck}
                            setValue={setIsVerticalCheck}
                        />
                    </div>

                    {
                        subtitleRender && (
                            <div className="col-12">
                                <Checkbox
                                    text={t("subtitles")}
                                    id="subtitles"
                                    value={subtitleCheck}
                                    setValue={setSubtitleCheck}
                                />
                                {
                                    subtitleCheck
                                    && 
                                    <TextEditor
                                        segments={subtitleSegments}
                                        setModifiedSegments={setModifiedSegments}
                                        loader={subtitleCutLoader}
                                    />
                                }
                            </div>
                        )
                    }
                </>
            )
        );
    };

    const renderProcessing = () => {
        return (
            project?.status === 3 && (
                <div>
                    {qpos === 0 && (
                        <div>
                            <div className="my-3 text-secondary">
                                {t("processing")} &nbsp;
                                {project.remainingTime && (
                                    <>
                                        {t("timeLeft")}{" "}
                                        {timeSpanToTime(project.remainingTime)}
                                    </>
                                )}
                            </div>
                            <div
                                className="progress my-3"
                                role="progressbar"
                                aria-label=""
                                aria-valuenow={
                                    project.percentReady
                                        ? (project.percentReady * 100).toFixed(
                                            2
                                        )
                                        : 0
                                }
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    style={{
                                        width: `${project.percentReady
                                            ? project.percentReady * 100
                                            : 0
                                            }%`,
                                    }}
                                >
                                    {project.percentReady
                                        ? (project.percentReady * 100).toFixed(
                                            2
                                        )
                                        : 0}
                                    %
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="d-grid gap-2">
                        <Button
                            variant={"outline-danger"}
                            size="lg"
                            onClick={cancelProject}
                        >
                            {t("cansel")}
                        </Button>
                    </div>
                </div>
            )
        );
    };

    return (
        <Card>
            {error && <Alert
                key={"danger"}
                variant={"outline-danger"}
                onClose={() => setShowError(false)}
                dismissible
            >
                <h4 className="alert-heading">
                    {t("error")}
                </h4>
                {error}
            </Alert>}
            {project &&
                <>
                    <div className="link text-secondary d-flex align-items-center mb-1">
                        <h3 className="flex-grow-1">{projectName}</h3>

                        {project?.status === 4 && (
                            <>
                                <ShareVideo className="ms-1" projectId={project.id} videoType={currentVideoType} />
                                <a
                                    title={t("downloadMainVideo")} 
                                    style={{color:'blue'}}
                                    className="ms-1 btn btn-dark" 
                                    href={videoSource}
                                >
                                    <Download />
                                </a>
                                {
                                    videoPreviewSource?.length > 0 
                                    ? (
                                        <a
                                            title={t("downloadPreview")}
                                            style={{ color: 'lightgreen' }}
                                            className="ms-1 btn btn-dark"
                                            href={videoPreviewSource}
                                        >
                                            <Download />
                                        </a>
                                    ) : null
                                }
                            </>
                        )}
                        {qpos > 0 && (
                            <span className="badge rounded-pill bg-warning" data-status={project.status}>
                                {t("queue")}: {getPeriodText(project.lastActive)}
                            </span>
                        )}
                    </div>

                    {renderPlayer()}
                    <form onSubmit={handleSubmit} className="row g-3">
                        {renderControls()}
                        {renderProcessing()}

                        {project?.lastError && (
                            <Alert
                                key={"danger"}
                                variant={"outline-danger"}
                                onClose={() => setShowError(false)}
                                dismissible
                            >
                                <h4 className="alert-heading">
                                    {project.renderState || "Render error"}
                                </h4>
                                <p>{project.lastError}</p>
                            </Alert>
                        )}

                        {project?.status === 4 && (
                            <div className="d-flex justify-content-around flex-wrap gap-3 btn-container mt-3">
                                {
                                    !subtitleRender &&
                                    <Button
                                        className="btn btn-width"
                                        variant={"outline-info"}
                                        size="lg"
                                        onClick={addSubtitles}
                                        disabled={subtitleLoader}
                                    >
                                            {subtitleLoader ? t("downloading") : t("subtitles")}
                                    </Button>
                                }
                                <Button
                                    className="btn btn-width"
                                    variant={"outline-info"}
                                    size="lg"
                                    onClick={createXmlAndDownload}
                                    disabled={isLoadingFiles}
                                >
                                    {isLoadingFiles ? t("downloading") : t("xml")}
                                </Button>
                            </div>
                        )}

                        {project?.status !== 3 && (
                            <div className="d-flex justify-content-around flex-wrap gap-3 btn-container mt-3">
                            <ButtonSubmit text={t("beginRender")}></ButtonSubmit>
                            {videoSource?.length > 0 ?
                                (<Button
                                    className="btn btn-width"
                                    variant={"outline-info"}
                                    size="lg"
                                    onClick={handlePreview}
                                >
                                    {t("createPreview")}
                                </Button>) : null
                            }
                                <Button
                                    className="btn btn-width"
                                    variant={"outline-danger"}
                                    size="lg"
                                    onClick={removeProject}
                                >
                                    {t("delete")}
                                </Button>
                            </div>
                        )}
                    </form>
                </>}
        </Card>
    );
}
