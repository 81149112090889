import { useEffect, useCallback } from 'react';

import {
	$getRoot,
	$getSelection,
	$isRangeSelection,
	SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_NORMAL,
} from "lexical";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType } from '@lexical/utils';

import { CustomParagraphNode } from '../CustomParagraphNode';

const ParagraphFocusPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const clearFocus = () => {
    const root = $getRoot();
    const children = root.getChildren();

    if (!children) return;

    children.forEach((node) => {
      if (node instanceof CustomParagraphNode) {
        node.setFocus('false');
      }
    });
  };

  const checkFocus = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
  
      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);
  
        if (paragraphNode instanceof CustomParagraphNode) {
          const currentFocus = paragraphNode.getFocus();
  
          if (currentFocus !== 'true') {
            clearFocus();
            paragraphNode.setFocus('true');
  
            return true;
          }
        }
      }
  
      return false;
    })
  }, [editor]);

  useEffect(() => {
    const unregisterSelectionChangeCommand = editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      checkFocus,
      COMMAND_PRIORITY_NORMAL
    );

    return () => {
      unregisterSelectionChangeCommand();
    };
  }, [checkFocus, editor]);

  return null;
};

export default ParagraphFocusPlugin;